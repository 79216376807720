<template>
  <div class="goodDetail">
    <div class="bg1">123</div>
    <div class="goodCont">
      <div class="tohome">
        当前位置：<span style="cursor: pointer" @click="toHome">首页</span>＞<span>产品详情</span>
      </div>
      <div class="goodInfo">
        <div class="leftInfo">
          <img :src="goodDetail.imgaddr" alt="" />
        </div>
        <div class="rightInfo">
          <div class="goodName">
            {{ goodDetail.title }}
          </div>
          <div class="goodSpec">生产厂家：{{ goodDetail.comm }}</div>
          <div class="goodSpec">规格：{{ goodDetail.spec }}</div>
          <div class="goodSpec">
            {{ goodDetail.wh }}
          </div>
          <div class="goodInst">
            {{ goodDetail.instructions }}
          </div>
        </div>
      </div>
      <div class="goodMenu">
        <div class="menuBox">
          <div class="menuTitle" @click="actIndex = 0" :class="{ actMenu: actIndex == 0 }">
            产品说明书
          </div>
          <div class="menuTitle" @click="actIndex = 1" :class="{ actMenu: actIndex == 1 }">
            产品详情
          </div>
        </div>
        <div class="tpBox" v-if="actIndex == 0">
          <img src="../assets/tp1.png" v-if="goodDetail.id == 1" alt="">
          <img src="../assets/tp2.png" v-if="goodDetail.id == 2" alt="">
          <img src="../assets/tp3.png" v-if="goodDetail.id == 3" alt="">
        </div>
        <div class="sms" v-else>
          <div class="sms1" v-if="goodDetail.id == 1">
            【药品名称】
            <br />
            通用名称：感冒灵颗粒
            <br />
            汉语拼音：Ganmaoling Keli
            <br />
            【成份】三叉苦、金盏银盘、野菊花、岗梅、咖啡因、对乙酰氨基酚、马来酸氣苯那敏、薄荷油。辅料为蔗糠粉。
            <br />
            【性状】本品为浅棕色至深棕色题粒；味甜、微苦。
            <br />
            【功能主治】解热镇病。用于感冒引起的头病，发热。鼻寨，流涕。咽痛。
            <br />
            【规格】每袋重10克（含对乙酰氨基酚0.2克）。
            <br />
            【用法用量】开水冲服，一次10克(1袋)，一日3次。
            <br />
            【不良反应】偶见皮疹、尊麻疹、药热及粒细胞减少；可见困倦、嗜睡、口渴、虚弱感；长期大量用药会导致肝肾功能异常。
            <br />
            【禁忌】严重肝肾功能不全者禁用。
            <br />
            【注意事项】1、忌烟、酒及辛辣、生冷、油腻食物。
            <br />
            2、不宜在服药期间同时服用滋补性中成药。
            <br />
            3、本品含对乙酰氨基酚、马来酸氣苯那敏、咖啡因。服用本品期间不得钦酒或含有
            酒精的饮料：不能同时服用与本品成份相似的其他抗感冒药；肝、肾功能不全者慎用：膀胱颈梗阻、甲状腺功能亢进、青光眼、高血压和前列腺肥大者慎用；孕妇及哺乳期妇女慎用；服药期间不得驾驶机、车、船、从事高空作业、机械作业及操作精密仪器。
            <br />
            4、脾胃虚寒、症见腹痛、喜暖、海泻者慎用。
            <br />
            5、糖尿病患者及有心脏病等慢性病严重者应在医师指导下服用。
            <br />
            6、儿童、年老体弱者应在医师指导下服用。
            <br />
            7、服药3天后症状无改善，或症状加重，或出现新的严重症状如胸闷、心悸等应立即停药，并去医院就诊。
            <br />
            8、对本品过敏者禁用，过敏体质者慎用。
            <br />
            9、本品性状发生改变时禁止使用。
            <br />
            10、儿童必须在成人监护下使用。
            <br />
            11、请将本品放在儿童不能接触的地方。
            <br />
            12、如正在使用其他药品、使用本品前请咨询医师或药师。
            <br />
            【药物相互作用】
            <br />
            1、与其他解热镇痛药并用，有增加肾毒性的危险。
            <br />
            2、与其他药物同时使用可能会发生药物相互作用，详情请咨询医师或药师。
            <br />
            【贮藏】密封。
            <br />
            【包装】复合膜；每盒装9袋。
            <br />
            【有 效 期】24个月
            <br />
            【执行标准】国家药典委员会药典业发(1998)第115号文件所附质量标准
            <br />
            【批准文号】国药准字Z44021940
            <br />
            【说明书修订日期】2007年05月15日
            <br />
            【生产企业】
            <br />
            委托方：华润三九医药股份有限公司
            <br />
            注册地址：深圳市龙华新区观润高新园区观清路1号
            <br />
          </div>
          <div class="sms2" v-if="goodDetail.id == 2">
            【产品名称】藿香正气水
            <br />
            【商品名/商标】府庆
            <br />
            【规格】玻璃瓶 10ml*10支
            <br />
            【主要成份】苍术、陈皮、厚朴（姜制）、白芷、茯苓、大腹皮、生半夏、甘草浸膏、广藿香油、紫苏叶油。辅料为乙醇。
            <br />
            【性状】本品为深棕色的澄清液体(久贮略有浑浊)；味辛、苦。
            <br />
            【功能主治/适应症】
            <br />
            解表化湿，理气和中。用于外感风寒、内伤湿滞或夏伤暑湿所致的感冒，症见头痛昏重、胸膈痞闷、脘腹胀痛、呕吐泄泻；胃肠型感冒见上述证候者。
            <br />
            【用法用量】口服。一次5～10毫升，一日2次，用时摇匀。
            <br />
            【注意事项】1.忌烟、酒及辛辣、生冷、油腻食物，饮食宜清淡。2.不宜在服药期间同时服用滋补性中药。3.有高血压、心脏病、肝病、糖尿病、肾病等慢性病严重者应在医师指导下服用藿香正气水。4.儿童、孕妇、哺乳期妇女、年老体弱者应在医师指导下服用。5.吐泻严重者应及时去医院就诊。6.本品含乙醇（酒精）40％～50％，服药后不得驾驶机、车、船、从事高空作业、机械作业及操作精密仪器。7.严格按用法用量服用，本品不宜长期服用。8.服药3天症状无缓解，应去医院就诊。9.对本品及酒精过敏者禁用，过敏体质者慎用。10.本品性状发生改变时禁止使用。11.儿童必须在成人监护下使用。12.请将本品放在儿童不能接触的地方。13.如正在使用其他药品，使用藿香正气水前请咨询医师或药师。
            <br />
            【药物相互作用】如与其他药物同时使用可能会发生药物相互作用，详情请咨询医师或药师。
            <br />
            【贮藏】密封。
            <br />
            【有效期】24个月。
            <br />
            【生产厂家】四川天府康达药业集团府庆制药有限公司
            <br />
            【药品上市许可持有人】四川天府康达药业集团府庆制药有限公司
            <br />
            【批准文号】国药准字Z51022378
            <br />
            【生产地址】四川省仁寿县视高经济开发区桂花大道6号
            <br />
            【条形码】6959390600090
            <br />
            【药品本位码】86902266000179
          </div>
          <div class="sms3" v-if="goodDetail.id == 3">
            通用名称：小柴胡颗粒
            <br />
            汉语拼音：XiaochaihuKeli
            <br />
            【成份】柴胡、黄芩、姜半夏、党参、生姜、甘草、大枣、辅料为蔗糖。
            <br />
            【性状】本品为黄色至棕褐色的颗粒：味甜，
            <br />
            【功能主治】解表散热，疏肝和胃。用于外感病，邪犯少阳证，症见寒热往来、胸
            胁苦满、食欲不振、心烦喜呕、口苦咽干。
            <br />
            【规格】每袋装10克
            <br />
            【用法用量】开水冲服。一次1~2袋，一日3次。
            <br />
            【不良反应】尚不明确。
            <br />
            【禁忌】尚不明确。
            <br />
            【注意事项】
            <br />
            1、忌烟、酒及辛辣、生冷、油腻食物。
            <br />
            2、不宜在服药期间同时服用滋补性中成药。
            <br />
            3、风寒感冒者不适用。
            <br />
            4、糖尿病患者及有高血压、心脏病、肝病、肾病等慢性病严重者应在医师指导下服用。
            <br />
            5.儿童、孕妇、哺乳期妇女、年老体弱者应在医师指导下服用。
            <br />
            6.发热体温超过38.5℃的患者，应去医院就诊。
            <br />
            7.服药三天症状无缓解，应去医院就诊。
            <br />
            8.对本品过敏者禁用，过敏体质者慎用。
            <br />
            9.本品性状发生改变时禁止使用。
            <br />
            10.儿童必须在成人监护下使用。
            <br />
            11、请将本品放在儿童不能接触的地方。
            <br />
            12、如正在使用其他药品，使用本品前请咨询医师或药师。
            <br />
            【药物相互作用】如与其他药物同时使用可能会发生药物相互作用，详情请咨询医师或药师。
            <br />
            【贮藏】密封。
            <br />
            【包装】复合铝袋包装，（1）每盒10袋：（2）每盒6袋：（3）每盒20袋。
            <br />
            【有效期】24个月
            <br />
            【执行标准】（中国药典》2010年版一部
            <br />
            【批准文号】国药准字Z44020211
            <br />
            【说明书修订日期】2010年10月01日
            <br />
            【生产企业】
            <br />
            企业名称：广州白云山光华制药股份有限公司
            <br />
            生产地址：广州市萝岗区镇龙柯岭路2号
            <br />
            注册地址：广州市海珠区南石路1号
            <br />
            邮政编码：510285
            <br />
            电话号码：①客户服务咨询电话：8009992828
            <br />
            ②质量专线：020-84352975
            <br />
            网址：www.gzghyy.com
            如有问题可与生产企业联系
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      actIndex: 0,
      goodList: [
        {
          id: 1,
          imgaddr: require("@imgs/1.png"),
          title: "感冒灵颗粒(999)",
          comm: "华润三九医药股份有限公司",
          spec: "10g*9袋",
          wh: "【批准文号】国药准字Z44021940",
        },
        {
          id: 2,
          imgaddr: require("@imgs/2.png"),
          title: "藿香正气水",
          comm: "四川天府康达药业集团府庆制药有限公司",
          spec: "10ml*10支",
          wh: "【批准文号】国药准字Z51022378",
        },
        {
          id: 3,
          imgaddr: require("@imgs/3.png"),
          title: "小柴胡颗粒(有糖)",
          comm: "广州白云山光华制药股份有限公司",
          spec: "10g*10袋",
          wh: "【批准文号】国药准字Z44020211",
        },
      ],
      id: "",
      goodDetail: {},
    };
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      var goodList = this.goodList.filter((v) => {
        return v.id == this.id;
      });
      this.goodDetail = goodList[0];
    }
    console.log(this.goodDetail);
  },
  methods: {
    toHome() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.goodMenu {
  .sms {
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 20px;
    text-align: left;
  }

  .tpBox {
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 20px;
  }

  .menuBox {
    display: flex;
    gap: 20px;
    background-color: #eee;
    padding: 10px;
    border-radius: 4px;

    .menuTitle {
      background-color: #fff;
      border: 1px solid #fff;
      color: #333;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      border-radius: 4px;
      cursor: pointer;
    }

    .actMenu {
      border: 1px solid #333;
    }
  }
}

.goodDetail {
  .bg1 {
    width: 195px;
    height: 50px;
  }

  .goodCont {
    width: 1200px;
    margin: 0 auto;
    margin-top: 30px;
    min-height: 449px;

    .tohome {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 300;
      text-align: left;
    }

    .goodInfo {
      display: flex;
      margin-top: 40px;

      .leftInfo {
        width: 200px;
        height: 200px;
        margin-right: 20px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .rightInfo {
        text-align: left;

        .goodName {
          font-size: 24px;
          font-weight: bold;
        }

        .goodSpec {
          font-size: 14px;
          color: #4d4d4d;
          margin-top: 10px;
        }

        .goodInst {
          font-size: 18px;
          color: #4d4d4d;
          margin-top: 20px;
        }
      }
    }
  }
}
</style>